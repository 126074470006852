import React, {useState} from 'react';
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import axios from 'axios';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { useForm } from 'react-hook-form';

const ContactForm = () => {
  const [submited, setSubmited] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode:'onChange'
  });
  const onSubmit = async (data) => {
    const token = btoa(`${process.env.ZENDESK_RECEIPT_RECEIVE_EMAIL}/token:${process.env.ZENDESK_API_CONTACT_TOKEN}`)
    try {
      await axios.post('https://retach.zendesk.com/api/v2/requests.json',{
        request: {
          comment: { body:data.comment },
          requester:{
            name:data.name ,
            email:data.email
          }
        }
      }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Basic ${token}`
        }
      })
      setSubmited(true)
    } catch (e) {
      NotificationManager.error('送信に失敗しました。')
    }

  };

  return (
    <section className="contact-area pt-100 pb-100">
      <NotificationContainer />
      <div className="container">
        <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" />
                        contact
                    </span>
          <h1>お問い合わせ</h1>
          <p >製品に関する機能・操作方法・その他のご要望についてはお問い合わせにて受け付けています。
          </p>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">

            <div className="contact-form">
              {submited
                ? <div className="alert alert-success" role="alert">
                    <h4 className="alert-heading">送信が完了しました。</h4>
                  </div>
                : <form id="contactForm" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                          <input {...register('name', {required: "必須入力です"})} className="form-control" placeholder="お名前" />
                        {errors.name && <p>{errors.name.message}</p>}

                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                          <input type="email" {...register('email', {required: "必須入力です"})} className="form-control" placeholder="メールアドレス" />
                        {errors.email && <p>{errors.email.message}</p>}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                          <textarea {...register('comment', {required: '必須入力です'})} className="form-control" cols="30" rows="6" placeholder="お問い合わせ内容" />
                        {errors.comment && <p>{errors.comment.message}</p>}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="default-btn" >
                        <i className="flaticon-tick"></i>
                        送信 <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              }

            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default ContactForm