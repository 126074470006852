import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';
import ContactForm from '../components/Contact/ContactForm';
import SEO from '../components/App/SEO';

const Contact = () => {
    return (
        <Layout>
          <SEO
            title="お問い合わせ"
            desc="リタッチのお問い合わせです。"
            pathname={'/contact'}
            type={'Article'}
            shortName="お問い合わせ"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
          />
            <Navbar />
            <PageBanner
                pageTitle="お問い合わせ"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="お問い合わせ"
            />
            {/*<ContactInfo />*/}
            <ContactForm />
            <Footer />
        </Layout>
    );
};

export default Contact